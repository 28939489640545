import React, { FC, useEffect, useState } from 'react';

import { TPatientMriViewerSelector } from './PatientMriViewerSelector.types';

import { ContrastType, MRIRecord, LocalStorageUpdateDetail } from '../../types';
import { STORAGE_KEY_PATIENT_MRI } from '../../utils';
import PatientMriSelector from '../PatientMriSelector/PatientMriSelector';

const SUBSELECT: number = +(window.location.href.split('/').at(-1) || 0);

const PatientMriViewerSelector: FC<TPatientMriViewerSelector> = () => {
  const [contrast, setContrast] = useState<ContrastType>(ContrastType.NULL);
  const [mri, setMri] = useState<MRIRecord>({} as MRIRecord);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (evt: StorageEvent | CustomEvent<LocalStorageUpdateDetail<Array<MRIRecord>>>) => {
      if (
        (evt instanceof StorageEvent && evt.key === STORAGE_KEY_PATIENT_MRI) ||
        (evt instanceof CustomEvent && evt.detail && evt.detail.key === STORAGE_KEY_PATIENT_MRI)
      ) {
        try {
          const item = window.localStorage.getItem(STORAGE_KEY_PATIENT_MRI);
          const mris: Array<MRIRecord> = item ? JSON.parse(item) : [];
          setMri(mris[SUBSELECT]);
          setContrast(mris[SUBSELECT].normalizedContrast);
        } catch {
          window.localStorage.removeItem(STORAGE_KEY_PATIENT_MRI);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange as EventListener);
    window.addEventListener('localstorage-update', handleStorageChange as EventListener);

    return () => {
      window.removeEventListener('storage', handleStorageChange as EventListener);
      window.removeEventListener('localstorage-update', handleStorageChange as EventListener);
    };
  }, []);

  useEffect(() => {
    window.PatientPage.setStatus();
  }, []);

  return (
    <div style={{ color: 'white' }}>
      <PatientMriSelector {...mri} contrast={contrast} setContrast={setContrast} loading={loading} setLoading={setLoading} />
    </div>
  );
};

export default PatientMriViewerSelector;
