import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import React, { ChangeEvent, FC, FocusEvent, SyntheticEvent, useEffect, useState } from 'react';

import { TPatientInfo, TPatientPersonalInfo } from './PatientPersonalInfo.types';

import API from '../../API';
import { MainModalCreate } from '../../MainModal';
import { FlashMessageType } from '../../const';
import { LocalStorageUpdateDetail } from '../../types';
import { isDevMode, STORAGE_KEY_PATIENT_INFO } from '../../utils';
import FlashMessageCreate from '../FlashMessage';

const hospitalCustomUrl = document.querySelector<HTMLDivElement>('#h-custom_url');
const FEATURE_LIST_LANGUAGE_SELECTOR = document.querySelector('#FEATURE_LIST_LANGUAGE_SELECTOR');
const HOSPITALS: Array<string> = FEATURE_LIST_LANGUAGE_SELECTOR ? JSON.parse(FEATURE_LIST_LANGUAGE_SELECTOR.innerHTML) : [];

const InputLabelNames = {
  preferredLang: 'レポート言語',
  gender: '性別',
  hospitalPatientId: '院内ID',
  birthDate: '生年月日(YYYY-MM-DD)',
  patientName: '氏名',
  email: 'メールアドレス',
  comment: '備考',
};
const genderTable = {
  male: '男性',
  female: '女性',
  other: '他/不明',
};

const PatientPersonalInfo: FC<TPatientPersonalInfo> = () => {
  const [storageData, setStorageData] = useState<TPatientInfo>();
  const [isLoading, setIsLoading] = useState(false); // State for sending requests
  const [editMode, setEditMode] = useState(false);
  const [editModeLock, setEditModeLock] = useState(false);
  const [formValues, setFormValues] = useState({
    hospitalPatientId: '',
    patientName: '',
    gender: '',
    birthDate: '',
    email: '',
    comment: '',
    preferredLang: '',
  });

  const handleChange = (evt: SelectChangeEvent | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = evt.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setEditModeLock(true);
  };
  const handleBlur = (evt: FocusEvent<HTMLInputElement>) => {
    const { name, value } = evt.target;
    evt.target.setCustomValidity('');
    const formattedDate = moment(value).format('YYYY-MM-DD');
    if (formattedDate === 'Invalid date') {
      evt.target.setCustomValidity('日付が正しくありません。');
      return;
    }
    setFormValues({
      ...formValues,
      [name]: formattedDate,
    });
  };

  const onFormSubmitHandle = (evt?: SyntheticEvent) => {
    if (evt) evt.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    let message = '';
    let type = FlashMessageType.Success;

    for (const [key, value] of Object.entries(formValues)) {
      formData.set(
        key.replace(/[A-Z]/g, (value) => '-' + value.toLowerCase()),
        value,
      );
    }

    API.setPatientInfo(formData)
      .then(({ data }) => {
        message = data.msgUser;
        type = data.msgType;
        window.PatientPage.setStatus().then(() => {
          setEditModeLock(false);
          setEditMode(false);
          setIsLoading(false);
        });
      })
      .catch((err) => {
        message = err.response.data.msgUser;
        type = err.response.data.msgType;
        setIsLoading(false);
      })
      .finally(() => {
        FlashMessageCreate({ message, type, isSelfDestroyable: true });
      });
  };

  // Run it once just to quickly update the UI.
  useEffect(() => {
    const item = window.localStorage.getItem(STORAGE_KEY_PATIENT_INFO);
    let data: TPatientInfo = {} as TPatientInfo;
    if (item) {
      try {
        data = JSON.parse(item);
      } catch {
        window.localStorage.removeItem(STORAGE_KEY_PATIENT_INFO);
      }
    }
    setStorageData(data);
    setFormValues({
      hospitalPatientId: data.hospitalPatientId || '',
      patientName: data.patientName || '',
      gender: data.gender || 'other',
      birthDate: data.birthDate || '',
      email: data.email || '',
      comment: data.comment || '',
      preferredLang: data.preferredLang || 'JP',
    });
  }, []);

  useEffect(() => {
    const handleStorageChange = (evt: StorageEvent | CustomEvent<LocalStorageUpdateDetail<TPatientInfo>>) => {
      if (
        (evt instanceof StorageEvent && evt.key === STORAGE_KEY_PATIENT_INFO) ||
        (evt instanceof CustomEvent && evt.detail && evt.detail.key === STORAGE_KEY_PATIENT_INFO)
      ) {
        const item = window.localStorage.getItem(STORAGE_KEY_PATIENT_INFO);
        let data: TPatientInfo = {} as TPatientInfo;
        if (item) {
          try {
            data = JSON.parse(item);
          } catch {
            window.localStorage.removeItem(STORAGE_KEY_PATIENT_INFO);
          }
        }
        setStorageData(data);
        if (!editMode) {
          setFormValues({
            hospitalPatientId: data.hospitalPatientId || '',
            patientName: data.patientName || '',
            gender: data.gender || 'other',
            birthDate: data.birthDate || '',
            email: data.email || '',
            comment: data.comment || '',
            preferredLang: data.preferredLang || 'JP',
          });
        }
      }
    };

    window.addEventListener('storage', handleStorageChange as EventListener);
    window.addEventListener('localstorage-update', handleStorageChange as EventListener);

    return () => {
      window.removeEventListener('storage', handleStorageChange as EventListener);
      window.removeEventListener('localstorage-update', handleStorageChange as EventListener);
    };
  }, [editMode]);

  useEffect(() => {
    const onWindowLeave = (evt: BeforeUnloadEvent) => {
      evt.preventDefault();
      evt.returnValue = true;
    };

    if (editModeLock) window.addEventListener('beforeunload', onWindowLeave);

    return () => {
      if (editModeLock) window.removeEventListener('beforeunload', onWindowLeave);
    };
  }, [editModeLock]);

  return (
    <div className='react-components-ppi'>
      {/* Header */}
      <div className='react-components-ppi__header-wrapper'>
        <h2 className='react-components-ppi__title capital-text'>受診者詳細 (受診回数:{storageData?.tagLabel})</h2>
        {editMode ? (
          <>
            <button
              onClick={() => {
                if (editModeLock) {
                  MainModalCreate({
                    modalName: 'patientPersonalInfoWarningModal',
                    isDisabled: true,
                    onAccept: () => {
                      onFormSubmitHandle();
                    },
                    onReject: () => {
                      setEditMode(false);
                      setEditModeLock(false);
                      setFormValues({
                        hospitalPatientId: storageData?.hospitalPatientId || '',
                        patientName: storageData?.patientName || '',
                        gender: storageData?.gender || 'other',
                        birthDate: storageData?.birthDate || '',
                        email: storageData?.email || '',
                        comment: storageData?.comment || '',
                        preferredLang: storageData?.preferredLang || 'JP',
                      });
                    },
                  });
                  return;
                }
                setEditMode(false);
                setEditModeLock(false);
                setFormValues({
                  hospitalPatientId: storageData?.hospitalPatientId || '',
                  patientName: storageData?.patientName || '',
                  gender: storageData?.gender || 'other',
                  birthDate: storageData?.birthDate || '',
                  email: storageData?.email || '',
                  comment: storageData?.comment || '',
                  preferredLang: storageData?.preferredLang || 'JP',
                });
              }}
              className='react-components-ppi__btn btn btn--additional'
              type='button'
              disabled={isLoading}
              tabIndex={8}
            >
              キャンセル
            </button>
            <label
              className={`react-components-ppi__btn btn btn--main ${isLoading ? 'btn--loading btn--disabled' : ''}`}
              htmlFor='react-components-ppi-form-submit-btn'
              tabIndex={9}
            >
              更新
            </label>
          </>
        ) : (
          <span onClick={() => setEditMode(true)} className='react-components-ppi__edit-btn'>
            <svg width='24' height='25' viewBox='0 0 24 25' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M14.0588 9.52L14.9788 10.44L5.91878 19.5H4.99878V18.58L14.0588 9.52ZM17.6588 3.5C17.4088 3.5 17.1488 3.6 16.9588 3.79L15.1288 5.62L18.8788 9.37L20.7088 7.54C21.0988 7.15 21.0988 6.52 20.7088 6.13L18.3688 3.79C18.1688 3.59 17.9188 3.5 17.6588 3.5ZM14.0588 6.69L2.99878 17.75V21.5H6.74878L17.8088 10.44L14.0588 6.69Z'
                fill='#009EE5'
              />
            </svg>
            編集
          </span>
        )}
        <span className='react-components-ppi__patient-id'>Brain-ID: {storageData?.patientId}</span>
      </div>
      {/* Form */}
      <form className='react-components-ppi__form-wrapper' onSubmit={onFormSubmitHandle}>
        <input type='submit' id='react-components-ppi-form-submit-btn' hidden />
        <FormControl variant='outlined' size='small' disabled={!editMode || isLoading}>
          <InputLabel htmlFor='hospitalPatientId'>{InputLabelNames.hospitalPatientId}</InputLabel>
          <OutlinedInput
            id='hospitalPatientId'
            name='hospitalPatientId'
            value={formValues.hospitalPatientId}
            label={InputLabelNames.hospitalPatientId}
            onInput={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
            inputProps={{ tabIndex: 1 }}
          />
        </FormControl>
        <Tooltip
          disableInteractive
          enterDelay={500}
          enterNextDelay={500}
          title={
            editMode && !storageData?.isEditable ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: '以下に該当するため、この情報は編集できません。<br>・結果レポートを確定しているため<br>・受診者本人が情報を入力しため',
                }}
              />
            ) : (
              ''
            )
          }
        >
          <FormControl variant='outlined' size='small' disabled={!storageData?.isEditable || !editMode || isLoading}>
            <InputLabel htmlFor='birthDate'>{InputLabelNames.birthDate}</InputLabel>
            <OutlinedInput
              id='birthDate'
              name='birthDate'
              value={formValues.birthDate}
              label={InputLabelNames.birthDate}
              onInput={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
              onBlur={handleBlur}
              inputProps={{ tabIndex: 4 }}
              required
            />
          </FormControl>
        </Tooltip>
        <FormControl variant='outlined' size='small' disabled={!editMode || isLoading}>
          <InputLabel htmlFor='patientName'>{InputLabelNames.patientName}</InputLabel>
          <OutlinedInput
            id='patientName'
            name='patientName'
            value={formValues.patientName}
            label={InputLabelNames.patientName}
            onInput={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
            inputProps={{ tabIndex: 2 }}
          />
        </FormControl>
        <Tooltip
          disableInteractive
          enterDelay={500}
          enterNextDelay={500}
          title={
            editMode && !storageData?.isEditable ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: '以下に該当するため、この情報は編集できません。<br>・結果レポートを確定しているため<br>・受診者本人が情報を入力しため',
                }}
              />
            ) : (
              ''
            )
          }
        >
          <FormControl variant='outlined' size='small' disabled={!storageData?.isEditable || !editMode || isLoading}>
            <InputLabel htmlFor='email'>{InputLabelNames.email}</InputLabel>
            <OutlinedInput
              id='email'
              name='email'
              value={formValues.email}
              label={InputLabelNames.email}
              onInput={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt)}
              inputProps={{ tabIndex: 5 }}
            />
          </FormControl>
        </Tooltip>
        <Tooltip
          disableInteractive
          enterDelay={500}
          enterNextDelay={500}
          title={
            editMode && !storageData?.isEditable ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: '以下に該当するため、この情報は編集できません。<br>・結果レポートを確定しているため<br>・受診者本人が情報を入力しため',
                }}
              />
            ) : (
              ''
            )
          }
        >
          <FormControl variant='outlined' size='small' disabled={!storageData?.isEditable || !editMode || isLoading}>
            {/* <FormLabel sx={{ fontSize: '16px' }}>{InputLabelNames.Gender}</FormLabel> */}
            <RadioGroup sx={{ justifyContent: 'start' }} row name='gender' value={formValues.gender} onChange={handleChange}>
              <FormControlLabel value='male' control={<Radio required inputProps={{ tabIndex: 3 }} />} label={genderTable.male} />
              <FormControlLabel value='female' control={<Radio required inputProps={{ tabIndex: 3 }} />} label={genderTable.female} />
              <FormControlLabel value='other' control={<Radio required inputProps={{ tabIndex: 3 }} />} label={genderTable.other} />
            </RadioGroup>
          </FormControl>
        </Tooltip>
        {HOSPITALS.includes(hospitalCustomUrl?.innerText || '') || isDevMode() ? (
          <FormControl size='small' disabled={!editMode || isLoading}>
            <InputLabel id='preferredLang'>{InputLabelNames.preferredLang}</InputLabel>
            <Select
              labelId='preferredLang'
              id='preferredLang'
              name='preferredLang'
              value={formValues.preferredLang}
              label={InputLabelNames.preferredLang}
              onChange={handleChange}
              inputProps={{ tabIndex: 6 }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
            >
              <MenuItem value='JP'>日本語</MenuItem>
              <MenuItem value='CN'>中国語（簡体字、中国）</MenuItem>
              <MenuItem value='HK'>中国語（繁体字、香港）</MenuItem>
              <MenuItem value='TW'>中国語（繁体字、台湾）</MenuItem>
              <MenuItem value='EN'>英語</MenuItem>
            </Select>
          </FormControl>
        ) : null}
        <TextField
          sx={{ gridRow: '1/4', gridColumn: '3/4' }}
          name='comment'
          value={formValues.comment}
          label={InputLabelNames.comment}
          onChange={handleChange}
          multiline
          minRows={6}
          maxRows={6}
          disabled={!editMode || isLoading}
          inputProps={{ tabIndex: 7 }}
        />
      </form>
    </div>
  );
};

export default PatientPersonalInfo;
