import { CircularProgress, FormControl, SelectChangeEvent, Divider } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { FC, MouseEvent } from 'react';

import { TPatientMriSelector } from './PatientMriSelector.types';

import API from '../../API';
import { ContrastType } from '../../types';

const PatientMriSelector: FC<TPatientMriSelector> = ({
  disabled = false,
  contrast = ContrastType.NULL,
  setContrast,
  loading,
  setLoading,
  institutionName,
  protocolName,
  seriesDescription,
  mriUuid,
}) => {
  return (
    <div style={{ position: 'relative' }}>
      <FormControl size='small' sx={{ m: 1, minWidth: 175 }} disabled={loading || disabled}>
        <Select
          disabled={loading || disabled}
          MenuProps={{
            disableScrollLock: true,
          }}
          sx={{
            backgroundColor: 'white',
            'div[class*="Mui-disabled"]': {
              cursor: 'not-allowed',
            },
          }}
          onClick={(evt: MouseEvent<HTMLDivElement>) => evt.stopPropagation()}
          value={contrast || ''}
          onChange={(evt: SelectChangeEvent) => {
            setLoading(true);
            API.setContrast({
              institutionName: institutionName || '',
              protocolName: protocolName || '',
              seriesDescription: seriesDescription || '',
              mriUuid,
              contrast: evt.target.value.length ? evt.target.value : null,
            })
              .then(({ data }) => {
                setContrast(data.data.contrast);
                window.PatientPage.setStatus();
              })
              .catch((err: unknown) => {
                console.error(err);
                setContrast(contrast);
              })
              .finally(() => setLoading(false));
          }}
        >
          {Object.values(ContrastType).map((value) => {
            return value ? (
              <MenuItem key={value} dense value={value}>
                {value}
              </MenuItem>
            ) : null;
          })}
          <Divider />
          <MenuItem dense value={ContrastType.NULL}>
            Clear
          </MenuItem>
        </Select>
      </FormControl>
      {loading && (
        <CircularProgress
          size={24}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
          }}
        />
      )}
    </div>
  );
};

export default PatientMriSelector;
